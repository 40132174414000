/*
 * @Author: your name
 * @Date: 2021-12-13 14:13:05
 * @LastEditTime: 2021-12-13 14:44:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Billing/WaybillFee/curd/columns.js
 */
/*
 * @Author: your name
 * @Date: 2021-12-08 18:10:06
 * @LastEditTime: 2021-12-08 18:16:29
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Address/defAddress/curd/columns.js
 */
import moment from "moment"
export default [
    {
        title: "账单号",
        dataIndex: "bill_no",
        key: "bill_no",
    },
    {
        title: "账单时间",
        dataIndex: "created_at",
        key: "created_at",
        customRender: (text) => {
            return moment(text * 1000).format("YYYY-MM-DD HH:mm:ss")
        }
    },
    {
        title: "核销状态",
        dataIndex: "write_off_status",
        key: "write_off_status",
        customRender: (text) => {
            let str = "";
            if (text === 1) {
                str = "未核销"
            } else if (text === 2) {
                str = "部分核销"
            } else if (text === 3) {
                str = "核销完成"
            }
            return str;
        }
    },
    {
        title: "运单数",
        dataIndex: "wayill_count",
        key: "wayill_count",
    },
    {
        title: "金额",
        dataIndex: "amount",
        key: "amount",
    },
    {
        title: '操作',
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
    },

]
