<!--
 * @Author: your name
 * @Date: 2021-12-13 13:59:05
 * @LastEditTime: 2021-12-13 14:36:42
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Billing/WaybillFee/index.vue
-->
<template>
  <div class="page-container">
    <div class="btn">
      <a-button type="primary" @click="exportWaybill('alone')">导出运单</a-button>
      <a-button type="primary" @click="exportWaybill('all')" style="margin-left:20px">批量导出账单</a-button>
    </div>
    <a-table size="small" bordered :columns="columns" :data-source="tableData" :scroll="{ x: 'max-content' }"
      :rowKey="(record,index) => record.id" :pagination="pagination" @change="handlerPage"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :customRow="customRow" :rowClassName="rowClassName">
      <template slot="action" slot-scope="record">
        <a class="btn-a" type="link" @click="check(record)">查看运单费用</a>
      </template>
    </a-table>
  </div>
</template>

<script>
  import columns from "./curd/columns";

  import {
    billFeeList,
  } from "@/api/Billing";
  import moment from "moment";
  import {
    exportData
  } from "@/api/Export"
  export default {
    name: "BillingFee",
    data() {
      return {
        selectIndex: null,
        tableData: [],
        rowSelection: {},
        columns: columns,
        moment,
        billId: 0,
        form: {
          page: 1,
          limit: 20,
        },
        drawer: {
          show: false,
        },
        selectedRowKeys: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 20,
          showTotal: (total) => `共 ${total} 条`,
          showSizeChanger: true,
          showQuickJumper: true,
        },
      };
    },
    created() {
      this.getData();
    },
    methods: {
       // 点击的样式
      rowClassName(record, index) {
            return (index === this.selectIndex) ? 'row-active' : ''
        },
        // 点击的效果
        customRow(record, index) {
            return {
                on: {
                    click: () => {
                        this.selectIndex = index;
                        console.log(this.selectIndex)
                    }
                }
            }
        },
      onSelectChange(val) {
        this.selectedRowKeys = val
      },
      handlerPage(p) {
        this.form.page = p.current;
        this.form.limit = p.pageSize;
        this.pagination.current = p.current;
        this.pagination.pageSize = p.pageSize;
        this.getData();
      },
      getData() {
        billFeeList(this.form).then((res) => {
          this.tableData = res.data.ResultLists.list;
          this.pagination.total = res.data.ResultLists.total;
        });
      },
      check(row) {
        this.$router.push({
          name: 'BillingFeeDetails',
          query: {
            id: row.id
          }
        })
      },
      exportWaybill(type) {
        if (this.selectedRowKeys.length < 1) {
          this.$message.warning("至少选择1单")
          return
        }
        if (type == 'alone' && this.selectedRowKeys.length != 1) {
          this.$message.warning("只能选择1单！")
          return
        }
        exportData({
          fileName: "导出账单",
          url: '/finance/bill/export',
          params: {
            billIds: this.selectedRowKeys
          },
          fileType: 'xlsx'
        })
      }
    },
  };
</script>

<style lang="less" scoped>
  .btn {
    padding: 20px;
  }

  .box {
    .title {
      display: flex;
      font-size: 10px;
      justify-content: space-between;
    }

    .verification {
      display: inline-block, ;
      color: #c97482;
      height: 20px;
      width: 60px;
      background: #fee3e4;
      text-align: center;
      border-radius: 15px;
    }

    .part_verification {
      display: inline-block, ;
      color: #f9d27e;
      height: 20px;
      width: 80px;
      background: #fdeaca;
      text-align: center;
      border-radius: 15px;
    }

    .un_verification {
      display: inline-block, ;
      color: #3e95e6;
      height: 20px;
      width: 80px;
      background: #a9d7f7;
      text-align: center;
      border-radius: 15px;
    }

    .table {
      .head {
        display: flex;
        justify-content: space-between;
        //   font-weight: bolder;
        margin-top: 15px;
      }
    }
  }
</style>